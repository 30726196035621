<template>
	<v-card class="d-flex" outlined>
		<div class="d-flex flex-column">
			<v-subheader>{{ name }}</v-subheader>
			<h1 class="pa-4 pt-0" >{{ value }}</h1>
		</div>
		<v-spacer />
		<v-icon class="mr-4" large>{{icon}}</v-icon>
	</v-card>
</template>

<script>
export default {
	props: {
		name: String,
		icon: String,
		value: String | Number,
	},
};
</script>